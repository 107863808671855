// Import Splide.js library
import { Splide } from "@splidejs/splide";
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// Splide web component
class LogoSlider extends HTMLElement {
  constructor() {
    super();
    this.autoScroll = this.getAttribute('auto-scroll') === 'true';
  }

  connectedCallback() {
    if(this.autoScroll){
      new Splide(this, {
        type   : 'loop',
        drag   : 'free',
        focus  : 'center',
        perPage: 3,
        gap: '5rem',
        arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        pagination: false,
        classes: {
          arrow: 'splide__arrow ls-arrow',
        },
        breakpoints: {
          1024: {
            perPage: 2.5,
            gap: '3rem',
            padding: {
              right: '-50%',
            },
          },
        },
        autoScroll: {
          speed: 2,
          autoStart: true,
        },
      }).mount( { AutoScroll } );
    } else {
      new Splide(this, {
        perPage: 4,
        perMove: 1,
        gap: '5rem',
        arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        pagination: false,
        classes: {
          arrow: 'splide__arrow ls-arrow',
        },
        breakpoints: {
          1024: {
            type   : 'loop',
            drag   : 'free',
            perPage: 2.5,
            perMove: 1,
            gap: '3rem',
            padding: {
              right: '-50%',
            },
          },
        }
      }).mount();
    }
  }
}

customElements.define('logo-slider', LogoSlider);